import { v4 as uuidv4 } from 'uuid';

declare global {
  // eslint-disable-next-line no-var
  var Android: AndroidTablet;
}
export interface AndroidTablet {
  reload: () => void;
  getAPKVersion: () => string | undefined;
}

export const getDeviceVersion = (): string => {
  let version: string | undefined;
  try {
    version = Android.getAPKVersion();
  } catch (ex) {
    // console.warn(ex);
  }
  if (!version) {
    try {
      const v = navigator.appVersion.match(/Chrome\/(\S+)/);

      if (v) {
        version = `Ch: ${v[0].split('/')[1]}`;
      } else {
        version = '...';
      }
    } catch (ex) {
      console.warn(`Can't read navigator appVersion ,${ex}`);
      version = 'unknown';
    }
  }
  return version;
};

export interface Credentials {
  id?: string;
  token?: string;
  shop?: string;
  brand?: string;
  pixel?: string;
}
export interface PlaylistInfo {
  _id: string;
  name: string;
  video: {
    video_id: string;
    originalname: string;
    size: number;
    url: string;
  };
  [key: string]: any;
}
export interface AppConfiguration {
  ticketsAPI: string;
  wssAPI: string;
  retailAppAPI?: string;
  printingAPI?: string;
  printingMode: string;
  printerHostname: string | undefined;
  playlist?: PlaylistInfo;
  hasPixel: number | undefined;
  idleTimeoutMovie: number;
}

export const getAppCredentials = (): Credentials => {
  const id = localStorage.getItem('id') ?? undefined;
  const token = localStorage.getItem('token') ?? undefined;
  const shop = localStorage.getItem('shop') ?? undefined;
  const brand = localStorage.getItem('brand') ?? undefined;
  const pixel = localStorage.getItem('pixel') ?? undefined;
  return { id, token, shop, brand, pixel };
};

export const resetAppCredentials = (): void => {
  localStorage.removeItem('id');
  localStorage.removeItem('token');
  localStorage.removeItem('shop');
  localStorage.removeItem('brand');
  localStorage.removeItem('pixel');
};

export const updateAppCredentials = (credentials: Credentials): void => {
  localStorage.setItem('id', credentials.id ?? '');
  localStorage.setItem('token', credentials.token ?? '');
  localStorage.setItem('shop', credentials.shop ?? '');
  localStorage.setItem('brand', credentials.brand ?? '');
  localStorage.setItem('pixel', credentials.pixel ?? '');
};

export const updateAppConfig = (config: AppConfiguration): void => {
  console.debug('new config');
  console.debug(config);

  localStorage.setItem('ticketsAPI', config.ticketsAPI ?? '');
  localStorage.setItem('wssAPI', config.wssAPI ?? '');
  localStorage.setItem('hasPixel', `${config.hasPixel}` ?? '');
  localStorage.setItem('printingMode', config.printingMode ?? '');
  localStorage.setItem('printingAPI', config.printingAPI ?? '');
  localStorage.setItem('printerHostname', config.printerHostname ?? '');
  localStorage.setItem('idleTimeoutMovie', `${config.idleTimeoutMovie}` ?? '');
  localStorage.setItem(
    'playlist',
    config.playlist ? JSON.stringify(config.playlist) : ''
  );
  localStorage.setItem('retailAppAPI', `${config.retailAppAPI}` ?? '');
};

export const getRetailAppAPI = (): string | undefined => {
  return localStorage.getItem('retailAppAPI') ?? undefined;
};

export const getTicketsAPI = (): string | undefined => {
  return 'https://api.darsery.app/api/pool/fortuna-tickets/dev';
  // return localStorage.getItem('ticketsAPI') ?? undefined;
};

export const getPrintingAPI = (): string | undefined => {
  return localStorage.getItem('printingAPI') ?? undefined;
};

export const getScreenSaverURL = (): string => {
  const defaultURL =
    'https://api.darsery.app/api/pool/download/oregon/video?v=touchscreen20210427-1640012726682.mp4';
  try {
    let playlist = JSON.parse(
      localStorage.getItem('playlist') ?? '{}'
    ) as PlaylistInfo;
    if ((playlist.video?.url ?? '').length > 0) {
      return playlist.video.url ?? defaultURL;
    }
  } catch (err) {
    console.error('error parsing screensaver config');
    console.error(err);
    return defaultURL;
  }
  return defaultURL;
};

export const getWebSocketAPI = (): string | undefined => {
  return localStorage.getItem('wssAPI') ?? undefined;
};

// const getCookie = (name: string): string | undefined => {
//   const cookies = document.cookie.split('; ');
//   if (cookies.length === 0) {
//     return undefined;
//   }
//   return cookies.find((row) => row.startsWith(name))?.split('=')[1];
// };

// export const setCookie = (name: string, value: string): void => {
//   document.cookie = `${name}=${value}; expires=Sun, 16 Jul 2030 00:23:41 GMT; path=/`;
// };

export const reloadApp = (): void => {
  try {
    if (window.Android !== undefined && Android.reload !== undefined) {
      Android.reload();
    } else {
      window.location.reload();
    }
  } catch (ex) {
    console.error('reloadApp err ', ex);
  }
};

Storage.prototype.setObject = function (key: string, value: unknown) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key: string) {
  const value = this.getItem(key);
  return value && JSON.parse(value);
};
